@tailwind base;

@tailwind components;

@tailwind utilities;

[v-cloak] {
  display: none;
}

.tooltipwrapper .tooltip {
  @apply invisible;
}

.tooltipwrapper:hover .tooltip {
  @apply visible;
}

@layer base {
  input {
    @apply dark:text-gray-50;
    @apply dark:bg-gray-800;
  }

  select {
    @apply dark:text-gray-50;
    @apply dark:bg-gray-800;
  }

  textarea {
    @apply dark:text-gray-50;
    @apply dark:bg-gray-800;
  }
}
